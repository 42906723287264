import React from "react";
import AudioRecorder from "./components/AudioRecorder";
import AnimatedWave from "./components/AnimatedWave";
import "./styles/voice_chat.css";
import { Config } from "./config";
const VoiceChat = () => {
  const getResponse = async (audioBlob, callback) => {
    // console.log(audioBlob);
    const formData = new FormData();
    formData.append("file", audioBlob); // 'file' should match here
    // Log FormData entries
    // formData.forEach((value, key) => {
    //   console.log(`${key}:`, value);
    // });
    const transcription = await fetch(`${Config.server}/transcription`, {
      headers: { Accept: "application/json" },
      method: "POST",
      body: formData,
    });

    console.log(transcription);
    callback(await transcription.json());
  };

  return (
    <>
    <main>
      <div id="voice-divider">
        <AnimatedWave />

        <div id="recorder">
          <AudioRecorder GetAudio={getResponse} />
        </div>

        <div id="transcription">

        </div>
      </div>
      </main>
    </>
  );
};

export default VoiceChat;
