import React from "react";

const AnimatedWave = () => {
  return (
    <div className="wave-wrapper">
      <div className="wave one"></div>
      <div className="wave two"></div>
      <div className="wave three"></div>
    </div>
  );
};

export default AnimatedWave;
