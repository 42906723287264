import { useMicVAD, utils } from "@ricky0123/vad-react";
import { useState, useEffect, useRef } from "react";
import { CombineWavBuffers } from "../extras/combineWavBuffers";
import {Config} from '../config'
import axios from "axios";

const AudioRecorder = () => {
  // console.log(process.env.ENV);
  const waves = document.querySelectorAll(".wave");
  const isSpacePressed = useRef(false);
  const isVadListening = useRef(false);
  const WaveBuffers = useRef([]);
  const isUserSpeaking = useRef(false);
  const addNextBuffer = useRef(false);

  const vad = useMicVAD({
    startOnLoad: false,
    submitUserSpeechOnPause: true,

    ortConfig: (ort) => {
      ort.env.wasm.wasmPaths =
        "https://cdn.jsdelivr.net/npm/onnxruntime-web@1.19.0/dist/";
    },
    workletURL:
      "https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.18/dist/vad.worklet.bundle.min.js",
    modelURL:
      "https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.18/dist/silero_vad.onnx",

    onSpeechStart: () => {
      console.log("User started talking");
      isUserSpeaking.current = true;
      if (isSpacePressed.current) addNextBuffer.current = true;
    },

    onSpeechEnd: (audio) => {
      console.log("User stopped talking");
      console.log(isSpacePressed.current);
      if (addNextBuffer.current) {
        WaveBuffers.current.push(utils.encodeWAV(audio));
        addNextBuffer.current = false;
      }
      isUserSpeaking.current = false;
    },
  });

  function waitForVadToFinish() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        console.log(isUserSpeaking.current);
        if (!isUserSpeaking.current) {
          clearInterval(interval);
          resolve();
        }
      }, 500); // Check every 500ms
    });
  }

  const handleSpaceDown = () => {};

  // Function to call when the spacebar is released
  const handleSpaceUp = async () => {
    // console.log(WaveBuffers.current);

    // const wavblob = new Blob(WaveBuffers.current, { type: "audio/wav" });
    const obj = CombineWavBuffers(WaveBuffers.current);

    WaveBuffers.current = [];
    // console.log(obj.blob, obj.arrayBuffer)
    const base64 = utils.arrayBufferToBase64(obj.arrayBuffer);
    const audioUrlAsData = `${base64}`;

    const audioElement = document.createElement("audio");
    audioElement.controls = true;
    audioElement.src = URL.createObjectURL(obj.blob);
    audioElement.autoplay = true;

    // Append the audio element to the body to play the sound
    document.getElementById("transcription").appendChild(audioElement);

    const response = await axios.post(
      `${Config.server}/transcribe`,
      {
        audioData: audioUrlAsData,
      },
      {
        headers: {
          "Content-Type": "application/json", // Ensure correct headers
        },
      }
    );

    console.log(response);
    const textElement = document.createElement("h5");
    textElement.innerText = response.data.text;

    // Append the audio element to the body to play the sound
    document.getElementById("transcription").appendChild(textElement);
    function fixBase64Padding(base64) {
      const padLength = (4 - (base64.length % 4)) % 4;
      return base64 + "=".repeat(padLength);
    }

    const base64ToBlob = (base64, type) => {
      const binaryString = atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: type });
    };
    axios
      .post(`${Config.server}/speech`, {
        text: response.data.text,
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        // const base64Audio = data.audio; // Get the Base64 string

        // Convert the Base64 string to ArrayBuffer
        // const arrayBuffer = base64ToArrayBuffer(base64Audio);

        const byteCharacters = atob(response.data.audio);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "audio/wav" });
        const aE = new Audio(URL.createObjectURL(blob));
        aE.controls = true;
        aE.autoplay = true;
        document.getElementById("transcription").appendChild(aE);
      })
      .catch((error) => {
        console.error("Error fetching audio:", error);
      });

    // const base64 = utils.arrayBufferToBase64(wavBuffer);
    // const audioUrlAsData = `${base64}`;
    // console.log(audioUrlAsData);
  };

  useEffect(() => {
    const handleKeyDown = async (e) => {
      if (e.code === "Space" && !isSpacePressed.current && isVadListening) {
        // await waitForVadToStartListening()
        console.log("down");
        isSpacePressed.current = true;

        waves.forEach((e) => {
          e.style["border-radius"] = `25%`;
          e.style.top = "80%";
        });

        handleSpaceDown();
      }
    };

    const handleKeyUp = async (e) => {
      if (e.code === "Space" && isSpacePressed.current && isVadListening) {
        isSpacePressed.current = false;

        waves.forEach((e) => {
          e.style["border-radius"] = `35%`;
          e.style.top = "90%";
        });

        await waitForVadToFinish();
        if (isVadListening.current) handleSpaceUp();
      }
    };

    // Add event listeners for keydown and keyup
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Clean up the event listeners on unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [isSpacePressed]);

  return (
    <div>
      <div className="record-main">
        <div className="record-buttons"><button
          onClick={() => {
            vad.start();
            isVadListening.current = true;
          }}
        >
          {" "}
          Start Chat{" "}
        </button>
        <button
          onClick={() => {
            vad.pause();
            isVadListening.current = false;
          }}
        >
          {" "}
          Stop Chat{" "}
        </button></div>
        {isSpacePressed.current ? <p>Release spacebar to send audio</p> : <p>Hold spacebar to record audio</p> }
        {isSpacePressed.current && isVadListening.current ? <p>User Speaking ....</p> : <p></p>}
      </div>

      {/* <button
        onClick={() => {
          vad.start();
        }}
      >
        start chat
      </button>

      <button
        onClick={() => {
          vad.pause();
        }}
      >
        stop chat
      </button>

      <h1>{vad.userSpeaking && "User is speaking"}</h1> */}
    </div>
  );
};
export default AudioRecorder;

// import React, { useState } from "react";

// const AudioUploader = () => {
//   const [recording, setRecording] = useState(false);
//   const [mediaRecorder, setMediaRecorder] = useState(null);
//   const [audioChunks, setAudioChunks] = useState([]);
//   const [isUploading, setIsUploading] = useState(false);

//   const startRecording = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       const recorder = new MediaRecorder(stream);
//       setMediaRecorder(recorder);

//       recorder.ondataavailable = (event) => {
//         setAudioChunks((prevChunks) => [...prevChunks, event.data]);
//       };

//       recorder.onstop = () => {
//         // The recording is stopped, we can now send the audio data
//         const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
//         sendAudioToServer(audioBlob);
//       };

//       recorder.start();
//       setRecording(true);
//     } catch (error) {
//       console.error("Error accessing microphone: ", error);
//     }
//   };

//   const stopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//       setRecording(false);
//     }
//   };

//   const sendAudioToServer = async (audioBlob) => {
//     setIsUploading(true);

//     const formData = new FormData();
//     formData.append("file", audioBlob, "audio.wav");

//     try {
//       const response = await fetch("http://localhost:4000/upload-audio", {
//         method: "POST",
//         body: formData,
//       });

//       if (response.ok) {
//         const transcription = await response.json();
//         console.log("Transcription: ", transcription);
//         // Handle transcription display logic here
//       } else {
//         console.error("Failed to get transcription");
//       }
//     } catch (error) {
//       console.error("Error sending audio to server:", error);
//     } finally {
//       setIsUploading(false);
//     }
//   };

//   return (
//     <div>
//       <h2>Record and Upload Audio</h2>
//       {recording ? (
//         <button onClick={stopRecording}>Stop Recording</button>
//       ) : (
//         <button onClick={startRecording}>Start Recording</button>
//       )}

//       {isUploading && <p>Uploading audio...</p>}
//     </div>
//   );
// };

// export default AudioUploader;

// import { useState, useRef, createElement, useEffect } from "react";

// const AudioRecorder = () => {
//   const [permission, setPermission] = useState(false);
//   const mediaRecorder = useRef(null);
//   const [recordingStatus, setRecordingStatus] = useState("inactive");
//   const [stream, setStream] = useState(null);
//   const [Recorder, setRecorder] = useState(null);

//   const leftChannelarr = useRef([]);
//   let recLength = useRef(0);

//   const [ws, setWs] = useState(null);
//   let socket;
//   const waves = document.querySelectorAll(".wave");
//   const clientId = useRef(
//     `client-${Date.now()}-${Math.floor(Math.random() * 10000)}`
//   );

//   const animationFrameId = useRef(null);

//   // const mimeType = "audio/mp3;codecs=pcm";

//   // useEffect(() => {
//   //   return () => {

//   // };
//   // }, [stopRecording])

//   const getMicrophonePermission = async () => {
//     if ("MediaRecorder" in window) {
//       try {
//         const streamData = await navigator.mediaDevices.getUserMedia({
//           audio: true,
//         });
//         setPermission(true);
//         setStream(streamData);
//       } catch (err) {
//         alert(err.message);
//       }
//     } else {
//       alert("The MediaRecorder API is not supported in your browser.");
//     }
//   };

//   const context = new AudioContext();

//   // function base64EncodeAudio(float32Array) {
//   //   const buffer = new ArrayBuffer(float32Array.length * 2);
//   //   const view = new DataView(buffer);
//   //   let offset = 0;
//   //   for (let i = 0; i < float32Array.length; i++, offset += 2) {
//   //     let s = Math.max(-1, Math.min(1, float32Array[i]));
//   //     view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
//   //   }

//   //   // Convert ArrayBuffer to Base64
//   //   let binary = "";
//   //   const bytes = new Uint8Array(buffer);
//   //   const chunkSize = 0x8000; // 32KB chunk size
//   //   for (let i = 0; i < bytes.length; i += chunkSize) {
//   //     const chunk = bytes.subarray(i, i + chunkSize);
//   //     binary += String.fromCharCode.apply(null, chunk);
//   //   }

//   //   return btoa(binary);
//   // }

//   // async function processAudioChunk(audioChunk) {
//   //   // Convert Blob to ArrayBuffer
//   //   const arrayBuffer = await audioChunk.arrayBuffer();
//   //   const audioBuffer = await context.decodeAudioData(arrayBuffer);
//   //   const channelData = audioBuffer.getChannelData(0);

//   //   const base64String = base64EncodeAudio(channelData);

//   //   return base64String;
//   // }

//   // async function reverseProcess(base64String) {
//   //   // Decode Base64 string into a binary string
//   //   const binaryString = atob(base64String);

//   //   // Create a Uint8Array to store binary data
//   //   const binaryData = new Uint8Array(binaryString.length);
//   //   for (let i = 0; i < binaryString.length; i++) {
//   //     binaryData[i] = binaryString.charCodeAt(i);
//   //   }

//   //   // Convert Uint8Array to ArrayBuffer
//   //   const arrayBuffer = binaryData.buffer;

//   //   // Convert ArrayBuffer back to Float32Array (PCM16 -> Float32)
//   //   const float32Array = pcm16ToFloat32(new DataView(arrayBuffer));

//   //   // Create a Blob from Float32Array (you can specify MIME type if needed)
//   //   const blob = new Blob([float32Array.buffer]);

//   //   return blob;
//   // }

//   // Helper function to convert PCM16 to Float32Array
//   // function pcm16ToFloat32(view) {
//   //   const float32Array = new Float32Array(view.byteLength / 2);
//   //   for (let i = 0; i < float32Array.length; i++) {
//   //     const int16 = view.getInt16(i * 2, true); // Read PCM16 values
//   //     float32Array[i] = int16 / 0x8000; // Convert to float in range [-1, 1]
//   //   }
//   //   return float32Array;
//   // }

//   const startRecording = async () => {
//     socket = new WebSocket("ws://localhost:4000");
//     setWs(socket);

//     socket.onopen = () => {
//       console.log("WebSocket connection established");

//       // Send the client ID to the server
//       socket.send(JSON.stringify({ type: "CONNECT", clientId: clientId.current }));
//     };

//     socket.onmessage = async (event) => {
//       console.log("Line 131 - Server Events: " + event);
//     };

//     socket.onclose = () => {
//       console.log("WebSocket connection closed");
//       socket.send(
//         JSON.stringify({ type: "DISCONNECT", clientId: clientId.current })
//       );
//     };

//     setRecordingStatus("recording");

//     //create new Media recorder instance using the stream
//     // const media = new MediaRecorder(stream, { type: mimeType });

//     const sampleRate = context.sampleRate;
//     const volume = context.createGain();

//     //set the MediaRecorder instance to the mediaRecorder ref
//     // mediaRecorder.current = media;

//     const audioInput = context.createMediaStreamSource(stream);
//     // connect the stream to the gain node
//     audioInput.connect(volume);

//     const analyzer = context.createAnalyser();
//     audioInput.connect(analyzer);

//     const bufferSize = 2048;
//     const recorder = (
//       context.createScriptProcessor || context.createJavaScriptNode
//     ).call(context, bufferSize, 1, 1);

//     setRecorder(recorder);

//     recorder.onaudioprocess = function (event) {
//       const samples = event.inputBuffer.getChannelData(0);

//       // console.log(recLength)

//       // we clone the samples

//       leftChannelarr.current.push(new Float32Array(samples));

//       recLength.current += bufferSize;
//     };

//     // we connect the recorder
//     volume.connect(recorder);

//     // start recording
//     recorder.connect(context.destination);
//     // The array we will put sound wave data in
//     const array = new Uint8Array(analyzer.fftSize);

//     function getPeakLevel() {
//       analyzer.getByteTimeDomainData(array);
//       return (
//         array.reduce(
//           (max, current) => Math.max(max, Math.abs(current - 127)),
//           0
//         ) / 128
//       );
//     }

//     const animateWaves = () => {
//       const peakvol = getPeakLevel();
//       for (let i = 0; i < waves.length; i++) {
//         waves[i].style["border-radius"] = `${35 - (10 + peakvol * 25)}%`;
//         waves[i].style.top = `${(peakvol * 100 - 90) * -1}%`;
//       }
//       // console.log(waves[0].style.top);

//       animationFrameId.current = requestAnimationFrame(animateWaves);
//     };

//     animateWaves();

//     //invokes the start method to start the recording process
//     // mediaRecorder.current.start(1000); // 1000ms timeslice

//     // mediaRecorder.current.ondataavailable = async (event) => {
//     //   if (typeof event.data === "undefined") return;
//     //   if (event.data.size === 0) return;

//     //   // localAudioChunks.push(event.data);
//     //   if (socket && socket.OPEN) {
//     //     const base64AudioChunk = await processAudioChunk(event.data);
//     //     console.log(base64AudioChunk);
//     //     const message = {
//     //       type: "audio",
//     //       clientId: clientId.current,
//     //       data: base64AudioChunk,
//     //     };

//     //     socket.send(JSON.stringify(message));
//     //   }
//     //   // console.log(base64String);
//     // };
//     // setAudioChunks(localAudioChunks);
//   };

//   function mergeBuffers(channelBuffer, recordingLength) {
//     let result = new Float32Array(recordingLength);
//     let offset = 0;

//     for (let i = 0; i < channelBuffer.length; i++) {
//       result.set(channelBuffer[i], offset);
//       offset += channelBuffer[i].length;
//     }

//     return Array.prototype.slice.call(result);
//   }

//   const stopRecording = () => {
//     setRecordingStatus("inactive");
//     if (animationFrameId.current) {
//       cancelAnimationFrame(animationFrameId.current);
//       animationFrameId.current = null;
//     }
//     Recorder.disconnect();

//     if (ws) {
//       ws.send(JSON.stringify({ type: 'DISCONNECT', clientId: clientId.current }));
//       ws.close();
//     }
//     // setleftChannel([]);
//     // setrecordingLength(0);
//     // console.log(audioChunks);
//     //stops the recording instance
//     // mediaRecorder.current.stop();
//     // mediaRecorder.current.onstop = () => {
//     //   //creates a blob file from the audiochunks data
//     //   const audioBlob = new Blob(audioChunks, { type: mimeType });
//     //   //creates a playable URL from the blob file.
//     //   const audioUrl = URL.createObjectURL(audioBlob);

//     //   const transcription = document.getElementById("transcription");
//     //   const a = document.createElement("audio");
//     //   a.src = audioUrl;
//     //   a.controls = true;
//     //   a.autoplay = true;
//     //   transcription.appendChild(a);

//     //   setAudio(audioUrl);
//     //   setAudioChunks([]);
//     // };
//   };

//   const sendMessage = () => {
//     console.log(recLength.current);
//     const PCM32fSamples = mergeBuffers(
//       leftChannelarr.current,
//       recLength.current
//     );
//     const PCM16iSamples = [];

//     for (let i = 0; i < PCM32fSamples.length; i++) {
//       let val = Math.floor(32767 * PCM32fSamples[i]);
//       val = Math.min(32767, val);
//       val = Math.max(-32768, val);

//       PCM16iSamples.push(val);
//     }
//     if (ws && ws.OPEN) {
//       ws.send(
//         JSON.stringify({
//           type: "audio",
//           clientId: clientId.current,
//           data: PCM16iSamples,
//         })
//       );
//     }

//     leftChannelarr.current = [];
//     recLength.current = 0;

//     console.log(PCM16iSamples);
//   };

//   return (
//     <div className="audio-controls">
//       {!permission ? (
//         <button onClick={getMicrophonePermission} type="button">
//           Get Microphone
//         </button>
//       ) : null}
//       {permission && recordingStatus === "inactive" ? (
//         <button onClick={startRecording} type="button">
//           Start Recording
//         </button>
//       ) : null}
//       {recordingStatus === "recording" ? (
//         <>
//           <button onClick={sendMessage} type="button">
//             Send Recording
//           </button>
//           <button onClick={stopRecording} type="button">
//             Stop Recording
//           </button>
//         </>
//       ) : null}
//     </div>
//   );
// };
// export default AudioRecorder;
