export function CombineWavBuffers(wavBuffers) {
  // Function to extract audio data from WAV buffer
  function extractAudioData(wavBuffer) {
    const view = new DataView(wavBuffer);
    let index = 12; // Start after the "RIFF" and "WAVE" headers

    // Find the data chunk
    while (true) {
      const chunkType = String.fromCharCode(...new Uint8Array(wavBuffer.slice(index, index + 4)));
      const chunkSize = view.getUint32(index + 4, true);
      
      if (chunkType === 'data') {
        return new Uint8Array(wavBuffer.slice(index + 8, index + 8 + chunkSize));
      }
      
      index += 8 + chunkSize;
      if (index >= wavBuffer.byteLength) {
        throw new Error('No data chunk found in WAV file');
      }
    }
  }

  // Extract WAV header from the first buffer
  const headerBuffer = wavBuffers[0].slice(0, 44);
  const headerView = new DataView(headerBuffer);

  // Calculate total audio data size
  const totalAudioSize = wavBuffers.reduce((acc, buffer) => {
    const view = new DataView(buffer);
    return acc + view.getUint32(40, true); // Size of data chunk
  }, 0);

  // Update WAV header with new size information
  headerView.setUint32(4, 36 + totalAudioSize, true); // Update RIFF chunk size
  headerView.setUint32(40, totalAudioSize, true); // Update data chunk size

  // Combine audio data
  const result = new Uint8Array(44 + totalAudioSize);
  result.set(new Uint8Array(headerBuffer), 0);

  let offset = 44;
  for (const wavBuffer of wavBuffers) {
    const audioData = extractAudioData(wavBuffer);
    result.set(audioData, offset);
    offset += audioData.length;
  }

  // Create ArrayBuffer from the Uint8Array
  const arrayBuffer = result.buffer;

  // Create Blob
  const blob = new Blob([arrayBuffer], { type: 'audio/wav' });

  // Return both Blob and ArrayBuffer
  return { blob, arrayBuffer };
}